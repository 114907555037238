import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../styles/footer.css";
import { Linkedin, Twitter } from "lucide-react";

export default function Footer() {
  const location = useLocation();

  const isActive = (path: string) => {
    if (path === "/home") {
      return location.pathname === "/" || location.pathname === "/home";
    }
    return location.pathname === path;
  };

  const handlePrivacyClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Navbar
      bg="light"
      variant="light"
      className="py-4 mt-5 footer-shadow footer"
    >
      <Container fluid className="d-flex flex-column">
        <Container className="d-flex flex-column flex-md-row justify-content-between mb-4">
          <Nav className="flex-column flex-md-row mb-3 mb-md-0">
            <Nav.Link
              as={Link}
              to="/"
              target="_top"
              rel="noreferrer"
              className={`footer-btn ${isActive("/home") ? "active" : ""}`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              target="_top"
              rel="noreferrer"
              className={`footer-btn ${isActive("/about") ? "active" : ""}`}
            >
              About
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              target="_top"
              rel="noreferrer"
              className={`footer-btn ${isActive("/contact") ? "active" : ""}`}
            >
              Contact
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/privacy"
              target="_top"
              rel="noreferrer"
              className={`footer-btn ${isActive("/privacy") ? "active" : ""}`}
              onClick={handlePrivacyClick}
            >
              Privacy
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/terms"
              target="_top"
              rel="noreferrer"
              className={`footer-btn ${isActive("/terms") ? "active" : ""}`}
            >
              Terms
            </Nav.Link>
          </Nav>
          <Nav className="justify-content-center">
            <Nav.Link
              className="animate-link me-3"
              href="https://www.linkedin.com/company/datasets/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <Linkedin className="social-icon" />
            </Nav.Link>
            <Nav.Link
              className="animate-link"
              href="https://x.com/datasetsai"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <Twitter className="social-icon" />
            </Nav.Link>
          </Nav>
        </Container>
        <Container className="text-center mt-auto">
          <p className="mb-1">
            DataSetsAI © {new Date().getFullYear()}. All Rights Reserved.
          </p>
          <p className="mb-0">
            An{" "}
            <a
              href="https://www.antcolony.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-antcolony-link"
            >
              AntColony
            </a>{" "}
            product offering comprehensive access to open datasets globally.
          </p>
        </Container>
      </Container>
    </Navbar>
  );
}
