import React, { FormEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "../styles/filter.css";
import {
  CategoriesDropdown,
  OrganizationsDropdown,
  SourceKindButton,
  TagsDropdown,
  TopicsDropdown,
} from "./searchfiltersbutton/index";

interface Props {
  setSearchParams: (params: URLSearchParams) => void;
  handleCloseModal: () => void;
  searchParams: URLSearchParams;
  showModal: boolean;
  qInput: string;
  setQInput: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const SearchFilter: React.FC<Props> = ({
  setSearchParams,
  handleCloseModal,
  searchParams,
  showModal,
  qInput,
  setQInput,
  handleSubmit,
}) => {
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(
    [],
  );
  const [selectedSourceKinds, setSelectedSourceKinds] = useState<number[]>(
    () => {
      const kinds = searchParams.getAll("sourceKind");
      return kinds.map((kind) => parseInt(kind, 10));
    },
  );

  useEffect(() => {
    setQInput(searchParams.get("q") || "");

    const q = searchParams.get("q")?.split(",").length || 0;
    const topicsCount = searchParams.getAll("topics")?.length || 0;
    const organizationsCount =
      searchParams.getAll("organizations")?.length || 0;
    const categoriesCount = searchParams.getAll("categories")?.length || 0;
    const tagsCount = searchParams.getAll("tags")?.length || 0;

    setSelectedCount(
      q + topicsCount + organizationsCount + categoriesCount + tagsCount,
    );
  }, [searchParams]);

  const handleTopicChange = (updatedTopics: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("topics");
    if (updatedTopics.length > 0) {
      updatedTopics.forEach((topic) => newSearchParams.append("topics", topic));
    }
    setSearchParams(newSearchParams);
  };

  const handleOrganizationChange = (updatedOrganization: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("organizations");
    if (updatedOrganization.length > 0) {
      updatedOrganization.forEach((org) =>
        newSearchParams.append("organizations", org),
      );
    }
    setSearchParams(newSearchParams);
  };

  const handleCategoryChange = (updatedCategory: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("categories");
    if (updatedCategory.length > 0) {
      updatedCategory.forEach((cat) =>
        newSearchParams.append("categories", cat),
      );
    }
    setSearchParams(newSearchParams);
  };

  const handleTagsChange = (updatedTags: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("tags");
    if (updatedTags.length > 0) {
      updatedTags.forEach((tag) => newSearchParams.append("tags", tag));
    }
    setSearchParams(newSearchParams);
  };

  const handleSourceKindChange = (sourceKind: number) => {
    setSelectedSourceKinds((prev) =>
      prev.includes(sourceKind)
        ? prev.filter((item) => item !== sourceKind)
        : [...prev, sourceKind],
    );

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("sourceKind");
    if (selectedSourceKinds.length > 0) {
      selectedSourceKinds.forEach((kind) =>
        newSearchParams.append("sourceKind", kind.toString()),
      );
    }
    setSearchParams(newSearchParams);
  };

  const resetFilters = () => {
    const newSearchParams = new URLSearchParams();
    setSearchParams(newSearchParams);
    setSelectedTags([]);
    setSelectedCategories([]);
    setSelectedOrganizations([]);
    setSelectedSourceKinds([]);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="modal-lg"
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="fs-4 fw-bold">Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-3">
        <Form onSubmit={handleSubmit}>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={qInput}
            className="p-3 mb-4 rounded-5 border-1 border-black"
            onChange={(e) => setQInput(e.target.value)}
          />
        </Form>
        <Col className="d-flex flex-column gap-4">
          <TopicsDropdown
            handleTopicChange={handleTopicChange}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setSelectedTopics={setSelectedTopics}
            selectedTopics={selectedTopics}
          />
          <TagsDropdown
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleTagsChange={handleTagsChange}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          <CategoriesDropdown
            handleCategoryChange={handleCategoryChange}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
          <OrganizationsDropdown
            handleOrganizationChange={handleOrganizationChange}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setSelectedOrganizations={setSelectedOrganizations}
            selectedOrganizations={selectedOrganizations}
          />
          <SourceKindButton
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleSourceKindChange={handleSourceKindChange}
            selectedSourceKinds={selectedSourceKinds}
            setSelectedSourceKinds={setSelectedSourceKinds}
          />
        </Col>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button
          className="filter-btn"
          variant="light"
          onClick={handleCloseModal}
        >
          Close
        </Button>
        <div className="d-flex align-items-center">
          <Button variant="light" className="filter-btn" onClick={resetFilters}>
            Reset Filters
          </Button>
          <Button
            variant="light"
            className="filter-btn"
            onClick={handleCloseModal}
          >
            Filters ({selectedCount})
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchFilter;
