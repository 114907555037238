import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import weblogo from "../assets/weblogo.png";
import "../styles/header.css";

const Header: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    if (path === "/home") {
      return location.pathname === "/" || location.pathname === "/home";
    }
    return location.pathname === path;
  };

  return (
    <Navbar
      expand="lg"
      className="bg-color d-flex flex-wrap justify-content-between p-4 py-3 mb-4 border-bottom"
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          className="align-items-center justify-content-center d-flex m-1"
        >
          <img src={weblogo} alt="logo" width="200" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-center">
          <Nav
            className="nav col-12 col-lg-auto mx-auto mb-2 mb-lg-0 justify-content-center align-items-center"
            style={{ fontFamily: "'Istok Web', sans-serif" }}
          >
            <Link
              className={`header-btn ${isActive("/home") ? "active" : ""}`}
              to="/"
            >
              Home
            </Link>
            <Link
              className={`header-btn ${isActive("/about") ? "active" : ""}`}
              to="/about"
            >
              About
            </Link>
            <Link
              className={`header-btn ${isActive("/categories") ? "active" : ""}`}
              to="/categories"
            >
              Categories
            </Link>
            <Link
              className={`header-btn ${isActive("/developer") ? "active" : ""}`}
              to="/developer"
            >
              Developer
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
