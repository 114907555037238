import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Mail, Phone, Linkedin, X } from "lucide-react";
import Footer from "components/footer";

export default function About() {
  return (
    <Container fluid className="p-0">
      <section className="py-4 py-md-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="mb-4 mb-md-0">
              <h2 className="mb-4">Our Mission</h2>
              <p>
                Datasets.ai focuses on consolidating datasets from government
                open data sources. We hope to aid developers, researchers and
                the general public in their research and data-driven projects.
              </p>
              <p>
                Our primary goal is to make data more accessible and usable for
                everyone. By aggregating and standardizing datasets from various
                government sources, we aim to reduce the time and effort
                required to find and utilize valuable public information.
              </p>
              <p>
                We believe that open data has the power to drive innovation,
                improve decision-making, and increase transparency in both
                public and private sectors. Through our platform, we strive to
                unlock this potential and empower users to create meaningful
                insights and solutions.
              </p>
            </Col>
            <Col md={6}>
              <img
                src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmaximumgovernance.com%2Fwp-content%2Fuploads%2F2017%2F03%2FOpen-data.png&f=1&nofb=1&ipt=dfebe0fccb6dcc559c7acc834b7a4ce5df31d77e15070e3bcd479e43ac00dd59&ipo=images&width=400"
                alt="Digital network visualization"
                className="img-fluid rounded"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-md-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 mb-md-5">More About Us</h2>
          <Row>
            <Col md={6} className="mb-4 mb-md-0">
              <h4>Our Approach</h4>
              <p>
                At Datasets.ai, we employ cutting-edge technology and data
                science techniques to collect, clean, and organize government
                open data. Our team of experts works tirelessly to ensure that
                the datasets we provide are accurate, up-to-date, and easy to
                use.
              </p>
              <p>
                We understand the challenges that come with working with diverse
                data sources. That's why we've developed a standardized format
                for our datasets, making it easier for users to integrate and
                analyze information from multiple sources seamlessly.
              </p>
            </Col>
            <Col md={6}>
              <h4>Our Impact</h4>
              <p>
                Since our inception, Datasets.ai has helped numerous
                organizations and individuals leverage open data for various
                purposes. From academic research to policy-making and business
                intelligence, our platform has been instrumental in driving
                data-informed decisions.
              </p>
              <p>
                We're proud to have contributed to projects that have made a
                real difference in areas such as urban planning, environmental
                conservation, and public health. By democratizing access to
                government data, we're fostering a culture of transparency and
                collaborative problem-solving.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="py-4 py-md-5"
        style={{ background: "linear-gradient(to bottom, #4ecca3, #1a2a6c)" }}
      >
        <Container>
          <h2 className="text-center text-white mb-4 mb-md-5">Get In Touch</h2>
          <Row>
            <Col md={6} className="mb-4 mb-md-0">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="First Name*" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Last name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="email" placeholder="Email*" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows={3} placeholder="Comments" />
                </Form.Group>
                <Button variant="light" type="submit" className="w-100">
                  Submit
                </Button>
              </Form>
            </Col>
            <Col md={6} className="text-white">
              <h3 className="mb-4">Contact</h3>
              <p>
                <Mail size={18} className="me-2" /> example@example.com
              </p>
              <p>
                <Phone size={18} className="me-2" /> 123 456 7891
              </p>
              <p>
                <Linkedin size={18} className="me-2" />{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/datasets/?viewAsMember=true"
                  className="text-white"
                >
                  LinkedIn
                </a>
              </p>
              <p>
                <X size={18} className="me-2" />{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/datasetsai"
                  className="text-white"
                >
                  X
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Container>
  );
}
