import "bootstrap/dist/css/bootstrap.min.css";
import About from "components/details/about";
import { Privacy } from "components/details/privacy";
import Terms from "components/details/terms";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import App from "./App";
import DatasetDetail from "./datasets/detail";
import DatasetList from "./datasets/list";
import ErrorPage from "./error-page";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const NotFound = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const hasNotified = React.useRef(false);

  useEffect(() => {
    if (!hasNotified.current) {
      enqueueSnackbar(
        "You have been redirected to the home page because the page you were trying to access does not exist.",
        { variant: "warning" },
      );
      hasNotified.current = true;
    }
  }, [enqueueSnackbar]);

  return <Navigate to="/" replace state={{ from: location }} />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <DatasetList />,
      },
      {
        path: "datasets/:name",
        element: <DatasetDetail />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>,
);

reportWebVitals();
